<script lang="ts">
	import Footer from '$lib/components/Footer.svelte'
	import Header from '$lib/components/Header.svelte'
</script>

<div
	class="flex min-h-screen w-screen flex-col overflow-x-hidden bg-[url('/wickedbackground.svg')] bg-cover bg-fixed"
>
	<Header />

	<main class="mx-auto mb-0 mt-4 box-border flex w-full flex-1 flex-col lg:max-w-7xl">
		<slot />
	</main>

	<Footer />
</div>
